import {
  LOGIN_REQUEST,
  LOGIN_REQUEST_FAILED,
  LOGIN_REQUEST_SUCCESS,
  LOGIN_REQUEST_SUCCESS_WITH_TOKEN,
} from "../actions/AuthActions";

const initialState = {
  isLoading: false,
  isError: false,
  token: localStorage.getItem("token") || "",
  userType: localStorage.getItem("userType") || "",
  isAuthenticated: !!localStorage.getItem("token"),
  message: "",
};

export const AuthReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_REQUEST:
      return { ...state, isLoading: true, isError: false, message: "" };

    case LOGIN_REQUEST_SUCCESS: {
      const { success, data, message } = payload || {};
      if (success && data?.token) {
        localStorage.removeItem("token");
        localStorage.removeItem("userType");
        localStorage.setItem("token", data.token);
        localStorage.setItem("userType", data.userType);
        return {
          ...state,
          isLoading: false,
          token: data.token,
          userType: data.userType,
          isAuthenticated: true,
          message: message || "",
        };
      } else {
        return {
          ...state,
          isLoading: false,
          isAuthenticated: false,
          message: message || "Login failed",
        };
      }
    }
    case LOGIN_REQUEST_SUCCESS_WITH_TOKEN: {
      const { success, data, message } = payload || {};
      if (success && data?.token) {
        localStorage.removeItem("token");
        localStorage.removeItem("userType");
        localStorage.setItem("token", data.token);
        localStorage.setItem("userType", data.userType);
        return {
          ...state,
          isLoading: false,
          token: data.token,
          userType: data.userType,
          isAuthenticated: true,
          message: message || "",
        };
      } else {
        return {
          ...state,
          isLoading: false,
          isAuthenticated: false,
          message: message || "Login failed",
        };
      }
    }

    case LOGIN_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: payload?.message || "Request failed",
      };

    default:
      return state;
  }
};
