import {
  addTravelPlan,
  deleteTravellingPlanEndPoint,
  getRoleEndPoint,
  getTravellingPlansEndPoint,
  membersEndPoints,
  updateTravelPlan,
} from "../../apiEndpoints.js/apiEndpoints";
import axiosInstance from "../../axiosInstance/axiosInstance";
import { tokenExtractor } from "../tokenExtract/tokenExtractFunc";

export const getRoleFunc = async () => {
  try {
    return axiosInstance.get(getRoleEndPoint);
  } catch (error) {
    handleError();
  }
};

export const getMember = async (genderValue, roleValue) => {
  try {
    // Extract token payload for potential authorization
    const tokenPayload = tokenExtractor();
    // Build the query params if genderValue and roleValue are being used
    let url = `${membersEndPoints}/${tokenPayload.country_id}?gender=${genderValue}`;
    let subCategoryTypeString = "";
    roleValue?.forEach((element) => {
      subCategoryTypeString += `&sub_category_type=${element.value}`;
    });
    url += subCategoryTypeString;
    // Make the API call
    return axiosInstance.get(url);
  } catch (error) {
    handleError(error);
  }
};

//add travel form
export async function addTravelFormFunc(data) {
  try {
    const tokenPayload = tokenExtractor();
    data.append("country_id", tokenPayload.country_id);
    return await axiosInstance.post(addTravelPlan, data);
  } catch (error) {
    handleError(error);
  }
}
export async function updateTravelFormFunc(data, id) {
  try {
    const tokenPayload = tokenExtractor();
    data.append("country_id", tokenPayload.country_id);
    return await axiosInstance.put(`${updateTravelPlan}/${id}`, data);
  } catch (error) {
    handleError(error);
  }
}

//fetch travelling plans for contingent
export async function getTravellingPlansFunc() {
  try {
    return await axiosInstance.get(getTravellingPlansEndPoint);
  } catch (error) {
    handleError(error);
  }
}

//delete travelling plan
export const deleteTravellingPlanFunc = async (id) => {
  try {
    return await axiosInstance.delete(`${deleteTravellingPlanEndPoint}/${id}`);
  } catch (error) {
    handleError(error);
  }
};

// Error handling function that logs the error and throws a more meaningful message
function handleError(error) {
  console.error("Error occurred:", error);
  throw {
    message: "Something went wrong! Please try again.",
    originalError: error,
  };
}
