import { MdOutlineTravelExplore } from "react-icons/md";
export const MenuList = [
  //Dashboard
  //Charts
  // {
  //   title: "Charts",
  //   classsChange: "mm-collapse",
  //   iconStyle: <i className="fas fa-chart-line" />,
  //   content: [
  //     {
  //       title: "RechartJs",
  //       to: "/chart-rechart",
  //     },
  //     {
  //       title: "Chartjs",
  //       to: "/chart-chartjs",
  //     },
  //     {
  //       title: "Sparkline",
  //       to: "/chart-sparkline",
  //     },
  //     {
  //       title: "Apexchart",
  //       to: "/chart-apexchart",
  //     },
  //   ],
  // },
];

export const TravelAdminMenuList = [
  {
    title: "Travel Admin",
    iconStyle: <MdOutlineTravelExplore size={25} />,
    content: [
      { title: "Calendar", to: "travel-module-admin" },
      { title: "Arrival", to: "arrival" },
      { title: "Departure", to: "departure" },
      { title: "Tracker", to: "tracker" },
    ],
  },

  ...MenuList,
];
export const ContingentMenuList = [
  {
    title: "Contingent",
    iconStyle: <i className="fas fa-home"></i>,
    content: [{ title: "Travel Plan", to: "travel-plan" }],
  },
  ...MenuList,
];
