import React from "react";
import Stack from "react-bootstrap/Stack";
import styles from "./styles/ArrivalCard.module.css";
import { v4 as uuidv4 } from "uuid";

function ArrivalModesCard({
  transportModes = [],
  activeLink,
  handleActiveBox,
}) {
  return (
    <Stack direction="horizontal" gap={3}>
      {transportModes?.map((ele, index) => {
        return (
          <>
            {ele.id !== 0 ? (
              <div
                key={uuidv4()}
                className={`${styles.box} rounded`}
                style={{
                  backgroundColor: "var(--primary-light)",
                  backgroundImage:
                    activeLink === ele.id
                      ? "linear-gradient(to right, var(--primary), var(--primary))"
                      : "linear-gradient(to right, var(--primary-light), var(--primary-dark))",
                  backgroundSize:
                    activeLink === ele.id ? "100% 100%" : "0% 100%",
                  backgroundRepeat: "no-repeat",
                  transition: "background-size 0.5s ease",
                }}
                onClick={() => handleActiveBox(ele)}
              >
                <Stack className="justify-content-center align-items-center">
                  <div>{ele?.icon}</div>
                  <div>{ele.title}</div>
                </Stack>
              </div>
            ) : null}
          </>
        );
      })}
    </Stack>
  );
}

export default ArrivalModesCard;
