import React, { useEffect, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import { Button, CardHeader } from "react-bootstrap";
import AddTravelPlan from "./AddTravelPlan";
import EditTravelPlan from "./EditTravelPlan";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTravellingPlan,
  getTravellingPlans,
} from "../../../store/actions/ContingentTravelActions/ContingentTravelAction";
import { tokenExtractor } from "../../../services/api/tokenExtract/tokenExtractFunc";

export const formatOFTicketRegex = /.(jpe?g|png)$/i;
function TravelPlan() {
  const [countryName, setCountryName] = useState("");
  // redux
  const dispatch = useDispatch();
  const store = useSelector((store) => store?.travelreduer);

  //edit form data state
  const [formData, setformData] = React.useState({});
  // add travel plan modal opening states
  const [show, setShow] = React.useState(false);

  // add travel plan modal opening edit states
  const [editshow, setEditShow] = React.useState(false);

  // add travel plan modal opening functions
  const closeAddTravelForm = () => setShow(false);
  const showAddTravelForm = () => setShow(true);

  // add travel plan modal opening edit
  const closeEditTravelForm = () => setEditShow(false);
  const showEditTravelForm = () => setEditShow(true);

  const handleEdit = (id) => {
    let EditTraveldData = store?.travellingPlans?.find((ele) => ele.id === id);
    setformData(EditTraveldData);
    showEditTravelForm();
  };

  //delete
  const handleDelete = (item) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      let res = willDelete && (await dispatch(deleteTravellingPlan(item.id)));

      if (res) {
        if (res?.success) {
          await swal(res?.message || "Operation was successful", {
            icon: "success",
          });
          await dispatch(getTravellingPlans({ type: "refresh" }));
          console.log(res);
        } else {
          swal(res?.payload?.message);
        }
      } else {
        swal("Your travelling plan is safe!");
      }
    });
  };
  useEffect(() => {
    (async () => {
      try {
        await dispatch(getTravellingPlans());
      } catch (error) {
        console.log(error.message);
      }
    })();
  }, []); // fetch the details of the travelling plans

  useEffect(() => {
    let token = tokenExtractor();
    setCountryName(token?.country_name);
  }, []);
  return (
    <>
      <PageTitle
        motherMenu={"Listing of Travel Plan"}
        activeMenu={"Travel Plan"}
        Button={() => (
          <Button variant="primary" onClick={showAddTravelForm}>
            Add Travel Plan
          </Button>
        )}
      />

      <div className="col-12">
        <div className="card">
          <CardHeader>
            <h3>Travel Plans</h3>
          </CardHeader>

          <div className="card-body">
            <div className="w-100 table-responsive">
              <div id="example_wrapper" className="dataTables_wrapper">
                <form>
                  <table id="example" className="display w-100 dataTable">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Organisation</th>
                        {/* <th>`Role`</th> */}
                        <th>Sport Department</th>
                        {/* <th>Member Type</th> */}
                        <th>Member Size</th>
                        <th>TICKET</th>
                        <th>Travel Type</th>
                        <th>Arrival/Departure Date</th>
                        <th>View</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {store?.travellingPlans?.length > 0 ? (
                        store?.travellingPlans?.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{countryName}</td>
                            {/* <td>{item.role}</td> */}
                            <td>{item.department}</td>
                            {/* <td>{item.memberType}</td> */}
                            <td>{item.size_of_team}</td>
                            <td>
                              {item?.doc_path ? (
                                formatOFTicketRegex.test(item?.doc_path) ? (
                                  <img
                                    src={`${process.env.REACT_APP_BASE_URL}${item?.doc_path}`}
                                    alt={item?.department}
                                    style={{ width: "100px", height: "auto" }}
                                    onClick={() =>
                                      window.open(
                                        `${
                                          process.env.REACT_APP_BASE_URL +
                                          item?.doc_path
                                        }`,
                                        "_blank"
                                      )
                                    }
                                  />
                                ) : (
                                  <a
                                    href={`${process.env.REACT_APP_BASE_URL}${item?.doc_path}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View PDF
                                  </a>
                                )
                              ) : (
                                "N/A"
                              )}
                            </td>

                            <td>{item.travel_type}</td>

                            {/* <td>
                              <Form>
                                <Form.Check type="switch" defaultChecked />
                              </Form>
                            </td> */}
                            <td>
                              {item.travel_type === "arrival"
                                ? item.date_of_arrival
                                : item.date_of_departure}
                            </td>
                            <td onClick={() => handleEdit(item?.id)}>
                              <i className="bi bi-eye-fill text-primary"></i>
                            </td>
                            <td onClick={() => handleDelete(item)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="red"
                                className="bi bi-trash3"
                                viewBox="0 0 16 16"
                              >
                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                              </svg>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="12" className="text-center">
                            Data is not available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddTravelPlan
        showAddTravelForm={show}
        closeAddTravelForm={closeAddTravelForm}
      />
      <EditTravelPlan
        closeEditTravelForm={closeEditTravelForm}
        showEditTravelForm={editshow}
        travelData={formData}
      />
    </>
  );
}

export default React.memo(TravelPlan);
