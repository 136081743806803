import React from "react";
import { Stack, Form, Button } from "react-bootstrap";
import { MdFlight } from "react-icons/md";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "./styles/Arrivalgroup.module.css";
import PageTitle from "../../layouts/PageTitle";
import swal from "sweetalert";

import {
  getSingleArrivalDepartureGroupData,
  removePlayerFromPlan,
} from "../../../store/actions/TravelAdminActions/TravelAdminActions";
import { useDispatch, useSelector } from "react-redux";

function ArrivalAndDepartureGroups() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let travelType = location?.pathname.split("/")[1];

  const { singleArrivalAndDepartureData = {} } = useSelector(
    (store) => store?.TravelAdminReducer
  );

  React.useEffect(() => {
    dispatch(getSingleArrivalDepartureGroupData("arrival", id));
    return () => {};
  }, [dispatch, id]);

  const handleOnChange = () => {};

  //const handle remove player
  const handleRemovePlayer = (playerDetails) => {
    let { member_id } = playerDetails;

    swal({
      title: "Are you sure?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      let res =
        willDelete && (await dispatch(removePlayerFromPlan(id, member_id)));
      if (res) {
        if (res?.success) {
          await swal(res?.message || "Operation was successful", {
            icon: "success",
          });
          await dispatch(getSingleArrivalDepartureGroupData("arrival", id));
        } else {
          swal(res?.payload?.message);
        }
      } else {
        swal("Your travelling plan is safe!");
      }
    });
  };
  return (
    <>
      <PageTitle
        motherMenu={
          travelType === "arrival" ? "Arrival Groups" : "Departure Groups"
        }
        activeMenu={"List"}
        Button={() => (
          <Button onClick={() => navigate(-1)} className="mb-2" size="sm">
            Go Back
          </Button>
        )}
      />
      <div className="row">
        {/* Left Card - Form */}
        <div className="col-lg-6">
          <div className="card">
            <div className={`card-header ${styles.customCardHeader}`}>
              Arrival Groups
            </div>
            <div
              className={`card-header justify-content-center align-items-center ${styles.customCardSubHeader}`}
            >
              <span className="">
                <MdFlight size={30} />
                <span className="ml-2 fs-3"> Flight</span>
              </span>
            </div>
            <div className={`card-body ${styles.customCardBody}`}>
              {/* Form Section */}
              <Form>
                {/* Name Input */}
                <Form.Group className="mb-3">
                  <Form.Label>Department Name</Form.Label>
                  <Form.Control
                    onChange={handleOnChange}
                    disabled
                    value={singleArrivalAndDepartureData?.department}
                    type="text"
                    placeholder="Chinese Taipei Team"
                  />
                </Form.Group>

                {/* Federation Dropdown */}
                <Form.Group className="mb-3">
                  <Form.Label>Federation</Form.Label>
                  <Form.Select
                    onChange={handleOnChange}
                    value={singleArrivalAndDepartureData?.country_name}
                  >
                    <option value={singleArrivalAndDepartureData?.country_name}>
                      {singleArrivalAndDepartureData?.country_name}
                    </option>
                    {/* Add more options as needed */}
                  </Form.Select>
                </Form.Group>

                {/* Arrival And Departure Date */}
                <Form.Group className="mb-3">
                  <Form.Label>
                    {travelType === "arrival"
                      ? "Arrival Date - DD/MM/YYYY"
                      : "Departure Date - DD/MM/YYYY"}
                  </Form.Label>
                  <Stack direction="horizontal" gap={2}>
                    <Form.Control
                      disabled
                      value={
                        travelType === "arrival"
                          ? new Date(
                              singleArrivalAndDepartureData?.date_of_arrival
                            ).getDate()
                          : new Date(
                              singleArrivalAndDepartureData?.date_of_departure
                            ).getDate()
                      }
                      onChange={handleOnChange}
                      type="text"
                      placeholder="DD"
                      maxLength={2}
                    />
                    <Form.Control
                      disabled
                      value={
                        travelType === "arrival"
                          ? new Date(
                              singleArrivalAndDepartureData?.date_of_arrival
                            ).getMonth() + 1
                          : new Date(
                              singleArrivalAndDepartureData?.date_of_departure
                            ).getMonth() + 1
                      }
                      onChange={handleOnChange}
                      type="text"
                      placeholder="MM"
                      maxLength={2}
                    />
                    <Form.Control
                      disabled
                      value={
                        travelType === "arrival"
                          ? new Date(
                              singleArrivalAndDepartureData?.date_of_arrival
                            ).getFullYear() + 1
                          : new Date(
                              singleArrivalAndDepartureData?.date_of_departure
                            ).getFullYear() + 1
                      }
                      onChange={handleOnChange}
                      type="text"
                      placeholder="YYYY"
                      maxLength={4}
                    />
                  </Stack>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    {" "}
                    {travelType === "arrival"
                      ? "Arrival time"
                      : "Departure time"}
                  </Form.Label>
                  <Stack direction="horizontal" gap={2}>
                    <Form.Control
                      disabled
                      value={
                        travelType === "arrival"
                          ? new Date(
                              singleArrivalAndDepartureData?.date_of_arrival
                            ).getHours()
                          : new Date(
                              singleArrivalAndDepartureData?.date_of_departure
                            ).getHours()
                      }
                      onChange={handleOnChange}
                      type="text"
                      placeholder="07"
                      maxLength={2}
                    />
                    <Form.Control
                      disabled
                      value={new Date(
                        singleArrivalAndDepartureData?.date_of_departure
                      ).getMinutes()}
                      onChange={handleOnChange}
                      type="text"
                      placeholder="20"
                      maxLength={2}
                    />
                  </Stack>
                </Form.Group>

                {/* Arrival And Departure location */}
                <Form.Group className="mb-3">
                  <Form.Label>
                    {travelType === "arrival"
                      ? "Arrival Location"
                      : "Departure Location"}
                  </Form.Label>

                  <Form.Control
                    disabled
                    value={
                      travelType === "arrival"
                        ? `${singleArrivalAndDepartureData?.arrival_location}`
                        : `${singleArrivalAndDepartureData?.departure_location}`
                    }
                    type="text"
                    placeholder=""
                  />
                </Form.Group>

                {/* Airline */}
                {/* <Form.Group className="mb-3">
                  <Form.Label>Airline</Form.Label>
                  <Form.Control type="text" placeholder="China Airlines (CI)" />
                </Form.Group> */}

                {/* Flight Number */}
                <Form.Group className="mb-3">
                  <Form.Label>
                    {singleArrivalAndDepartureData?.transportation_mode} Number
                  </Form.Label>
                  <Form.Control
                    onChange={handleOnChange}
                    disabled
                    value={singleArrivalAndDepartureData?.vehical_no}
                    type="text"
                    placeholder="75"
                  />
                </Form.Group>

                {/* Terminal and Arrival Time */}
                {/* <Form.Group className="mb-3">
                  <Form.Label>Terminal</Form.Label>
                  <Form.Control type="text" placeholder="3" />
                </Form.Group> */}

                {/* Departure Airport */}
                {/* <Form.Group className="mb-3">
                  <Form.Label>Departure Airport</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Taipei Taiwan Taoyuan International Airport"
                  />
                </Form.Group> */}

                {/* Comment Section */}
                {/* <Form.Group className="mb-3">
                  <Form.Label>Comment</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter any comment"
                  />
                </Form.Group> */}

                {/* Submit Button */}
                {/* <Button variant="primary" type="submit">
                  Submit
                </Button> */}
              </Form>
            </div>
          </div>
        </div>

        {/* Right Section - People List */}
        <div className="col-lg-6">
          <div className="card">
            <div className={`card-header ${styles.customCardHeader}`}>
              <span> People</span>
            </div>
            <div className={`card-body ${styles.customCardBodyOfTable}`}>
              <table className={`table ${styles.customeTable}`}>
                <thead>
                  <tr>
                    <th>Player ID</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Accreditation</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {singleArrivalAndDepartureData?.members?.map(
                    (item, index) => (
                      <tr key={index}>
                        <td>{item.member_id}</td>
                        <td>{item.first_name}</td>
                        <td>{item.last_name}</td>
                        <td>
                          <span className={`badge bg-primary`}>
                            {item.sub_category_name_view?.toUpperCase()}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`badge bg-danger`}
                            onClick={(e) => handleRemovePlayer(item)}
                          >
                            REMOVE
                          </span>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ArrivalAndDepartureGroups;
