import {
  LOGIN_USER,
  LOGIN_USERW_WITH_TOKEN,
} from "../../apiEndpoints.js/apiEndpoints";
import axiosInstance from "../../axiosInstance/axiosInstance";

export const loginUser = async (payload = {}) => {
  return axiosInstance.post(`${LOGIN_USER}`, payload);
};
export const loginUserWithToken = async (token = "") => {
  return axiosInstance.post(`${LOGIN_USERW_WITH_TOKEN}`, { token: token });
};
