export const LOGIN_USER = "auth/login";

export const LOGIN_USERW_WITH_TOKEN = "auth/verify-token";

// Travel Plans End Points

export const addTravelPlan = "/travelling-plans";
//update travelling plan
export const updateTravelPlan = "/travelling-plans";

//## Contingent

// 1 role
export const getRoleEndPoint = "/user-category-sub-types";

// 2 fetch Member end points
export const membersEndPoints = "/users/findUsersWithCountry";

//travelling plans end point
export const getTravellingPlansEndPoint = "travelling-plans";
export const deleteTravellingPlanEndPoint = "travelling-plans";

//travel adminEnd Pints
export const getCountryListEndPoint = "travelling-address/all-countries";

// calendar api end point
export const getCalendardataEndPoint = "calendar/events";

//trackedData
export const getTrackedDataEndPoint = "travelling-address/contingent";
//SinglePlayertrackedData
export const getSinglePlayerTrackedDataEndPoint = "travelling-address/all";

//trackedData for single
export const getTrackedDataOfSingleEndPoint = "travelling-address";

//players list end point
export const getAlplayersEndPoint = "travelling-plans/players";

//players list end point
export const getAllusersCountEndPoint = "travelling-address/active-status";

export const getAllArrivalAndDepartureDataEndPoint =
  "travelling-plans/travel-info";

export const getSingleArrivalDepartureGroupDataEndPoint = "travelling-plans";

export const removePlayerFromPlanEndPoint = "travelling-plans";
