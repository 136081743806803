import {
  FAILURE,
  REQUEST,
} from "../../actions/ContingentTravelActions/ContingentTravelAction";
import {
  GET_ARRIVAL_AND_DEPARTURE_LIST,
  GET_CALENDAR_DATA,
  GET_COUNTRIES_LIST,
  GET_PLAYER_LIST,
  GET_SINGLE_ARRIVAL_DEPARTURE_DATA,
  GET_SINGLE_PLAYER_TRACKED_DATA,
  GET_TRACKED_DATA,
  GET_USERS_COUNT,
} from "../../actions/TravelAdminActions/TravelAdminActions";

const intialState = {
  isLoading: false,
  isError: false,
  countryList: [],
  calendarData: [],
  trackedData: [],
  playerList: [],
  arrivalData: [],
  departureData: [],
  usersCount: [],
  singleArrivalAndDepartureData: {},
};

export const TravelAdminReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case REQUEST:
      return { ...state, isLoading: true, isError: false };
    case FAILURE:
      return { ...state, isLoading: false, isError: true };
    case GET_COUNTRIES_LIST:
      return {
        ...state,
        isLoading: false,
        countryList: payload?.data,
      };
    case GET_CALENDAR_DATA:
      return {
        ...state,
        isLoading: false,
        calendarData: payload?.data || payload,
      };
    case GET_TRACKED_DATA:
      return {
        ...state,
        isLoading: false,
        trackedData: payload,
      };
    case GET_PLAYER_LIST:
      return { ...state, isLoading: false, playerList: payload };
    case GET_ARRIVAL_AND_DEPARTURE_LIST: {
      let obj = { ...state, isLoading: false };
      if (payload?.dataOf === "arrival") {
        obj["arrivalData"] = payload.data?.data;
      } else if (payload?.dataOf === "departure") {
        obj["departureData"] = payload.data?.data;
      }
      return obj;
    }
    case GET_USERS_COUNT:
      return { ...state, isLoading: false, usersCount: payload };
    case GET_SINGLE_ARRIVAL_DEPARTURE_DATA:
      return {
        ...state,
        isLoading: false,
        singleArrivalAndDepartureData: payload,
      };
    case GET_SINGLE_PLAYER_TRACKED_DATA:
      return {
        ...state,
        isLoading: false,
        singlePLayerMapData: payload,
      };
    default:
      return state;
  }
};
