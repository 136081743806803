import React, { useContext } from "react";

/// React router dom
import { Routes, Route, Outlet } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { ThemeContext } from "../context/ThemeContext";
import TravelModuleAdminRoutes from "./routes/travelModuleAdminRoutes/TravelModuleAdminRoutes";
import ContingentRoutes from "./routes/contingentRoutes/ContingentRoutes";

const Markup = ({ userType }) => {
  return (
    <>
      <Routes>
        {/* Main Layout Routes */}
        <Route element={<MainLayout />}>
          {userType === "contingent" ? (
            <Route path="/*" element={<ContingentRoutes defaultRoute={""} />} />
          ) : userType === "travel_module_admin" ? (
            <Route
              path="/*"
              element={<TravelModuleAdminRoutes defaultRoute={"tracker"} />}
            />
          ) : (
            <Route path="/*" element={<Error404 />} />
          )}
        </Route>

        {/* Error Pages */}
        <Route path="page-error-400" element={<Error400 />} />
        <Route path="page-error-403" element={<Error403 />} />
        <Route path="page-error-404" element={<Error404 />} />
        <Route path="page-error-500" element={<Error500 />} />
        <Route path="page-error-503" element={<Error503 />} />

        {/* Catch-All for Undefined Routes */}
        <Route path="/*" element={<Error404 />} />
      </Routes>

      {/* <Setting /> */}
      <ScrollToTop />
    </>
  );
};

function MainLayout() {
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <div
      id="main-wrapper"
      className={`show ${sidebariconHover ? "iconhover-toggle" : ""} ${
        menuToggle ? "menu-toggle" : ""
      }`}
    >
      <Nav />
      <div
        className="content-body"
        style={{ minHeight: window.screen.height - 45 }}
      >
        <div className="container-fluid">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Markup;
