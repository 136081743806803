import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import PageTitle from "../../layouts/PageTitle";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { CiFilter, CiSearch } from "react-icons/ci";
import MapComponent from "../Map/MapComponent";
import TravelComponentHeaderCard from "./TravelComponentHeaderCard";
import GenericTable from "./Table";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  getCountryList,
  getTrackedData,
  getUsersCount,
} from "../../../store/actions/TravelAdminActions/TravelAdminActions";
import { getRole } from "../../../store/actions/ContingentTravelActions/ContingentTravelAction";
import PaginationComp from "../pagination/PaginationComp";
import { FaHistory, FaSearch } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";

const columns = [
  { label: "S.No", field: "s_no" },
  { label: "Name", field: "fullname" },
  { label: "KITID", field: "kitd_unique_id" },
  { label: "Date/Time", field: "date" },
  { label: "Address/Locations", field: "address" },
  { label: "Status", field: "status" },
  { label: "Country", field: "country_name" },
  { label: "History", field: "actions" },
];

function TrackerComponent() {
  const [searchInputValue, setsearchInputValue] = React.useState("");
  const [dataLimit, setdataLimit] = React.useState(10); // limits
  const [totalPage, settotalPage] = React.useState(1);
  const [currentPage, setcurrentPage] = React.useState(1);
  const [persisitFilterData, setpersisitFilterData] = React.useState({});
  const [toggleSearchAndFilter, settoggleSearchAndFilter] =
    React.useState("filter");

  const [playerLatLong, setplayerLatLong] = React.useState({});
  const dispatch = useDispatch();
  const { roles = [] } = useSelector((store) => store.travelreduer);
  const {
    countryList = [],
    trackedData = [],
    usersCount = [],
  } = useSelector((store) => store?.TravelAdminReducer);

  const location = useLocation();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    let checkFilter = Object.values(data).some((ele) => Boolean(ele));
    if (!checkFilter) {
      return;
    }
    let { date, start_time, end_time } = data;
    if (!date) {
      const now = new Date();
      const istDate = new Date(
        now.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })
      );
      date = `${istDate.getFullYear()}-${String(
        istDate.getMonth() + 1
      ).padStart(2, "0")}-${String(istDate.getDate()).padStart(2, "0")}`;
    }

    if (!start_time) {
      start_time = "00:00";
    }
    if (!end_time) {
      end_time = "23:59";
    }
    start_time = start_time && new Date(`${date}T${start_time}:00`);
    start_time = start_time && Math.floor(start_time.getTime());
    end_time = end_time && new Date(`${date}T${end_time}:00`);
    end_time = end_time && Math.floor(end_time.getTime());
    data.start_time = start_time;
    data.end_time = end_time;
    setpersisitFilterData(data);
    dispatch(getTrackedData({ ...data, limit: dataLimit }));
    setValue("start_time", "");
    setValue("date", "");
    setValue("end_time", "");
  };

  function handleToggle(value) {
    if (value === toggleSearchAndFilter) {
      return;
    }
    settoggleSearchAndFilter(value);
    reset();
    dispatch(
      getTrackedData({
        limit: dataLimit,
        page: currentPage,
      })
    );
  }

  useEffect(() => {
    (async () => {
      await dispatch(getCountryList());
      await dispatch(getRole);
      await dispatch(getUsersCount);
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      let { totalPage } = await dispatch(
        getTrackedData({
          ...persisitFilterData,
          limit: dataLimit,
          page: currentPage,
        })
      );
      settotalPage(totalPage);
    })();
  }, [dispatch, currentPage, dataLimit, persisitFilterData]);

  //handleSearch value
  const handleSearchInputValue = (e) => {
    // if (!e.target.value.trim()) return;
    let value = e.target.value.trim();
    setsearchInputValue(value);
  };

  //handle listing event of row
  const trOnClickEventHandler = (rowElement) => {
    setplayerLatLong({
      longitude: rowElement.longitude,
      latitude: rowElement.latitude,
      address: rowElement.address,
    });
  };

  ///handle limit of Data
  const handleDatalimit = (e) => {
    setdataLimit(e.target.value);
    dispatch(
      getTrackedData({
        ...persisitFilterData,
        limit: e.target.value,
      })
    );
  };
  const handlePagination = (number) => {
    dispatch(
      getTrackedData({ ...persisitFilterData, limit: dataLimit, page: number })
    );
  };
  const startTimeValue = watch("start_time");

  ///for actions
  const actions = [
    {
      label: "",
      className: "btn-primary",
      icon: <FaHistory />,
      onClick: (e, item) => {
        e.stopPropagation();
        location?.pathname === "/tracker"
          ? navigate(`${item?.kitd_unique_id}`)
          : navigate(`tracker/${item?.kitd_unique_id}`);
      },
    },
  ];
  //for search
  async function handleSearchFunc() {
    if (searchInputValue && searchInputValue.length > 0) {
      let { totalPage } = await dispatch(
        getTrackedData({
          kit_id: searchInputValue,
          limit: 1,
          page: 1,
        })
      );
      settotalPage(totalPage);
    } else {
      let { totalPage } = await dispatch(
        getTrackedData({
          ...persisitFilterData,
          limit: dataLimit,
          page: currentPage,
        })
      );
      settotalPage(totalPage);
    }
  }

  return (
    <>
      <PageTitle motherMenu="Arrival-Departure" activeMenu={"Tracker"} />
      {/* Header Card */}
      <div className="row">
        {usersCount.map((user, index) => (
          <div className="col-md-4" key={uuidv4()}>
            <TravelComponentHeaderCard
              title={user.title}
              count={user.count}
              percentage={user.percentage}
              icon={user.icon}
            />
          </div>
        ))}
      </div>
      <div className="card">
        <div className="card-body">
          <div className="row mb-3">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mb-3">
                {toggleSearchAndFilter === "filter" ? (
                  <>
                    {/* Country Label */}
                    <div className="col-lg-2">
                      <label>Select Country</label>
                      <select
                        className={`form-control`}
                        {...register("country", {})}
                      >
                        <option value="">Country</option>
                        {countryList.map((item, index) => {
                          return (
                            <option key={uuidv4()} value={item?.id}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {/* User Type Label */}
                    <div className="col-lg-2">
                      <label>Select User Type</label>
                      <select
                        className={`form-control`}
                        {...register("sub_category_type_id", {})}
                      >
                        <option value="">User Type</option>
                        {roles.map((ele, index) => {
                          return (
                            <React.Fragment key={uuidv4()}>
                              <option value={ele?.id}>
                                {ele?.sub_category_name_view}
                              </option>
                            </React.Fragment>
                          );
                        })}
                      </select>
                    </div>
                    {/* Date Label (Optional) */}
                    <div className="col-lg-2">
                      <label>Date</label>
                      <input
                        type="date"
                        className="form-control"
                        {...register("date")}
                      />
                    </div>
                    {/* Time Input (Optional) */}
                    <div className="col-lg-2">
                      <label>Start Time</label>
                      <input
                        type="time"
                        className="form-control"
                        {...register("start_time", {})}
                      />

                      {errors.start_time && (
                        <small className="text-danger">
                          {errors.start_time.message}
                        </small>
                      )}
                    </div>
                    <div className="col-lg-2">
                      <label>End Time</label>
                      <input
                        type="time"
                        className="form-control"
                        {...register("end_time", {
                          required: startTimeValue
                            ? "end time is required"
                            : "",
                        })}
                      />
                      {errors.end_time && (
                        <small className="text-danger">
                          {errors.end_time.message}
                        </small>
                      )}
                    </div>

                    {/* Search Button */}
                    <div className="col-lg-1 d-flex align-items-end">
                      <Button type="submit">Search</Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label>Search By KITID</label>
                        <InputGroup>
                          <FormControl
                            onChange={handleSearchInputValue}
                            type="text"
                            className="form-control input-default"
                            placeholder="Search"
                          />
                          <InputGroup.Text onClick={handleSearchFunc}>
                            <FaSearch />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                    </div>
                    <div className="col-lg-7"></div>
                  </>
                )}

                {/* Icons for Filter and Search */}
                <div className="col-lg-1 d-flex align-items-end">
                  <div className="d-flex align-items-center p-2 gap-2">
                    <CiFilter
                      size={30}
                      onClick={() => handleToggle("filter")}
                      style={{
                        cursor: "pointer",
                        color:
                          toggleSearchAndFilter === "filter"
                            ? "var(--primary)"
                            : "",
                      }}
                    />
                    <CiSearch
                      size={30}
                      onClick={() => handleToggle("search")}
                      style={{
                        cursor: "pointer",
                        color:
                          toggleSearchAndFilter === "search"
                            ? "var(--primary)"
                            : "",
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <MapComponent
        playerLatLong={playerLatLong}
        trackedData={trackedData || []}
      />
      <div className="mt-3">
        <GenericTable
          actions={actions}
          columns={columns}
          data={trackedData || []}
          trOnClickEvent={trOnClickEventHandler}
          PaginationComp={
            <PaginationComp
              handlePagination={handlePagination}
              currentpage={currentPage}
              setcurrentPage={setcurrentPage}
              size={totalPage}
              gutter={true}
              variant={""}
              bg={true}
              circle={false}
            />
          }
          LimitsSelectBox={() => {
            return (
              <>
                <div className="pb-2 row">
                  <div className="col-md-1">
                    <select
                      value={dataLimit}
                      className="form-control form-control-sm"
                      onChange={handleDatalimit}
                    >
                      <option value={"10"}>10</option>
                      <option value={"20"}>20</option>
                      <option value={"30"}>30</option>
                      <option value={"40"}>40</option>
                      <option value={"50"}>50</option>
                    </select>
                  </div>
                </div>
              </>
            );
          }}
        />
      </div>
    </>
  );
}

export default TrackerComponent;
