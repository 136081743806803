import {
  ADD_TRAVEL_DATA_REQ,
  ADD_TRAVEL_DATA_REQ_FAILED,
  ADD_TRAVEL_DATA_REQ_SUCCESS,
  FAILURE,
  GET_MEMBER,
  GET_TRAVELLING_PLANS,
  REQUEST,
  ROLES_REQ_SUCCESS,
  UPDATE_TRAVELLING_PLAN,
} from "../../actions/ContingentTravelActions/ContingentTravelAction";

const intialState = {
  isLoading: false,
  isError: false,
  message: false,
  roles: [],
  members: [],
  travellingPlans: [],
};
export const TravelReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ADD_TRAVEL_DATA_REQ:
      return { ...state, isLoading: true, isError: false };
    case ADD_TRAVEL_DATA_REQ_SUCCESS:
      return { ...state, isLoading: false, message: payload.message };
    case ADD_TRAVEL_DATA_REQ_FAILED:
      return { ...state, isLoading: false, isError: true };

    case REQUEST:
      return { ...state, isLoading: true, isError: false };
    case ROLES_REQ_SUCCESS:
      return {
        ...state,
        isLoading: false,
        roles: payload.data,
      };
    case GET_MEMBER:
      return { ...state, isLoading: false, members: payload?.data };

    case GET_TRAVELLING_PLANS:
      return { ...state, isLoading: false, travellingPlans: payload?.data };

    case UPDATE_TRAVELLING_PLAN:
      return { ...state, isLoading: true, isError: false };
    case FAILURE:
      return { ...state, isLoading: false, isError: true };
    default:
      return state;
  }
};
