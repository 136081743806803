import React from "react";

function TravelComponentHeaderCard({ title, count, percentage, icon }) {
  return (
    <div>
      <div className="card shadow-sm">
        <div className="card-body d-flex justify-content-between align-items-center">
          <div>
            <p className="card-title text-muted">{title}</p>
            <h3>{count}</h3>
            {/* <span className="text-success">{percentage}</span> */}
          </div>
          <div>
            <i
              style={{ color: "var(--primary)", fontSize: "25px" }}
              className={`icon-class ${icon}`}
            ></i>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TravelComponentHeaderCard;
