import {
  getAllArrivalAndDepartureDataFunc,
  getAllPlayersFunc,
  getAllUsersCountFunc,
  getCalendarDataFunc,
  getCountryListFunc,
  getPlayerSingleTrackedDataFunc,
  getSingleArrivalDepartureGroupDataFunc,
  getTrackedDataFunc,
  getTrackedDataFuncForSingle,
  removePlayerFromFunc,
} from "../../../services/api/TravelAdminServiceFunc/TravelAdminServiceFunc";

//commom actions
export const REQUEST = "REQUEST";
export const FAILURE = "FAILURE";

///fetch country list
export const GET_COUNTRIES_LIST = "GET_COUNTRIES_LIST";
//fetch calendar data
export const GET_CALENDAR_DATA = "GET_CALENDAR_DATA";

export const GET_TRACKED_DATA = "GET_TRACKED_DATA";
export const GET_SINGLE_PLAYER_TRACKED_DATA = "GET_SINGLE_PLAYER_TRACKED_DATA";
//
//player list
export const GET_PLAYER_LIST = "GET_PLAYER_LIST";

//
export const GET_ARRIVAL_AND_DEPARTURE_LIST = "GET_ARRIVAL_AND_DEPARTURE_LIST";

//users count
export const GET_USERS_COUNT = "GET_USERS_TYPE";

//users count
export const GET_SINGLE_ARRIVAL_DEPARTURE_DATA =
  "GET_SINGLE_ARRIVAL_DEPARTURE_DATA";

export const getCountryList = () => async (dispatch, getState) => {
  try {
    const state = getState();
    if (state?.TravelAdminReducer?.countryList.length > 0) {
      return;
    }
    dispatch({ type: REQUEST });
    const res = await getCountryListFunc();
    return dispatch({ type: GET_COUNTRIES_LIST, payload: res });
  } catch (error) {
    return dispatch({ type: FAILURE, payload: error });
  }
};

// get calendar data
export const getCalendarData =
  ({ mode: travel_type, transportation_mode, currentYear: year }) =>
  async (dispatch) => {
    try {
      let filterObj = { travel_type, year };
      if (transportation_mode) {
        filterObj.transportation_mode = transportation_mode;
      }
      dispatch({ type: REQUEST });
      let res = await getCalendarDataFunc(filterObj);
      let modifyArray = res?.data?.data.map((ele) => {
        if (ele.date_of_arrival) {
          return {
            ...ele,
            date_of_arrival: dateConverter(ele.date_of_arrival),
            start: dateConverter(ele.date_of_arrival),
            end: dateConverter(ele.date_of_arrival),
          };
        } else {
          return {
            ...ele,
            date_of_departure: dateConverter(ele.date_of_departure),
            start: dateConverter(ele.date_of_departure),
            end: dateConverter(ele.date_of_departure),
          };
        }
      }); //for change the data of date
      return dispatch({ type: GET_CALENDAR_DATA, payload: modifyArray });
    } catch (error) {
      return dispatch({ type: FAILURE, payload: error });
    }
  };

//get All team players
export const getAllPlayers = async (dispatch, getState) => {
  try {
    const state = getState();
    if (state?.TravelAdminReducer?.playerList.length > 0) {
      return;
    }
    dispatch({ type: REQUEST });
    let res = await getAllPlayersFunc();
    return dispatch({ type: GET_PLAYER_LIST, payload: res?.data?.data });
  } catch (error) {
    return dispatch({ type: FAILURE, payload: error });
  }
};

//get travel data according to the traveltype
export const getDataOfArrivalAndDeparture =
  (travelTypeValue, otherOptions = {}) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: REQUEST });
      let res = await getAllArrivalAndDepartureDataFunc(
        travelTypeValue,
        otherOptions
      );
      return dispatch({
        type: GET_ARRIVAL_AND_DEPARTURE_LIST,
        payload: { data: res?.data, dataOf: travelTypeValue },
      });
    } catch (error) {
      return dispatch({ type: FAILURE, payload: error });
    }
  };

//fetch tracked data
export const getTrackedData =
  (data = {}) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: REQUEST });
      const res = await getTrackedDataFunc(data);
      let newArray = res?.data?.data.map((ele) => {
        let date = new Date(ele?.locationData?.timestamp);
        let currentTime = new Date();
        let timeDifferenceInMilliseconds = currentTime - date;
        let timeDifferenceInMinutes = Math.floor(
          timeDifferenceInMilliseconds / 1000 / 60
        );

        return {
          ...ele.locationData,
          ...ele.user,
          date: `${date}`,
          status: timeDifferenceInMinutes <= 15 ? "active" : "inactive",
        };
      });
      let totalPage = Math.ceil(+res?.data?.total / +data?.limit);
      return dispatch({
        type: GET_TRACKED_DATA,
        payload: newArray,
        totalPage,
      });
    } catch (error) {
      return dispatch({ type: FAILURE, payload: error });
    }
  };
export const getSinglePlayerTrackedData =
  (payload) => async (dispatch, getState) => {
    try {
      dispatch({ type: REQUEST });
      const res = await getPlayerSingleTrackedDataFunc(payload);

      let newArray = res?.data?.data.map((ele) => {
        let date = new Date(ele?.timestamp);
        return { ...ele, date };
      });

      return dispatch({
        type: GET_SINGLE_PLAYER_TRACKED_DATA,
        payload: { ...res?.data, data: newArray },
      });
    } catch (error) {
      return dispatch({ type: FAILURE, payload: error });
    }
  };

export const getTrackedOfSingle = (allsportId) => async (dispatch) => {
  try {
    dispatch({ type: REQUEST });
    const res = await getTrackedDataFuncForSingle(allsportId);
    let newArray = res?.data?.data.map((ele) => {
      let date = new Date(ele?.locationData?.timestamp);
      let currentTime = new Date();
      let timeDifferenceInMilliseconds = currentTime - date;
      let timeDifferenceInMinutes = Math.floor(
        timeDifferenceInMilliseconds / 1000 / 60
      );

      return {
        ...ele.locationData,
        ...ele.user,
        date: `${date}`,
        status: timeDifferenceInMinutes <= 15 ? "active" : "inactive",
      };
    });

    return dispatch({ type: GET_TRACKED_DATA, payload: newArray });
  } catch (error) {
    return dispatch({ type: FAILURE, payload: error });
  }
};

export const getUsersCount = async (dispatch, getState) => {
  try {
    const state = getState();
    if (Object.keys(state?.TravelAdminReducer?.usersCount).length > 0) {
      return;
    }
    dispatch({ type: REQUEST });
    let res = await getAllUsersCountFunc();
    let newArray = [];
    for (let i in res?.data) {
      if (i === "allUserCount") {
        newArray.push({
          title: "Total Users",
          count: res?.data[i],
          percentage: "+55%",
          icon: "bi bi-people-fill",
        });
      } else if (i === "inActiveUsers") {
        newArray.push({
          title: "Inactive Users",
          count: res?.data[i],
          percentage: "+55%",
          icon: "bi bi-person-dash-fill",
        });
      } else if (i === "activeUserCount") {
        newArray.push({
          title: "Active Users",
          count: res?.data[i],
          percentage: "+55%",
          icon: "bi bi-person-check-fill",
        });
      }
    }
    return dispatch({ type: GET_USERS_COUNT, payload: newArray });
  } catch (error) {
    return dispatch({ type: FAILURE, payload: error });
  }
};

export const getSingleArrivalDepartureGroupData =
  (type, id) => async (dispatch) => {
    try {
      dispatch({ type: REQUEST });
      let res = await getSingleArrivalDepartureGroupDataFunc(type, id);
      dispatch({ type: GET_SINGLE_ARRIVAL_DEPARTURE_DATA, payload: res?.data });
    } catch (error) {
      return dispatch({ type: FAILURE, payload: error });
    }
  };
export const removePlayerFromPlan =
  (travellingPlanID, member_id) => async (dispatch) => {
    try {
      dispatch({ type: REQUEST });
      let res = await removePlayerFromFunc(travellingPlanID, member_id);
      console.log(res);
      return res?.data;
    } catch (error) {
      return dispatch({ type: FAILURE, payload: error });
    }
  };

///date converter
function dateConverter(dateString) {
  const dateObj = new Date(dateString);
  const year = dateObj.getUTCFullYear();
  const month = dateObj.getUTCMonth();
  const day = dateObj.getUTCDate();

  return new Date(year, month, day);
}
