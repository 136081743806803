import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { thunk } from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
import { TravelReducer } from "./reducers/ContingentTravelReducer/ContingentTravelReducer";
import { TravelAdminReducer } from "./reducers/TravelAdminReducer/TravelAdminReducer";
//import { reducer as reduxFormReducer } from 'redux-form';

const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  // posts: PostsReducer,
  auth: AuthReducer,
  travelreduer: TravelReducer,
  TravelAdminReducer: TravelAdminReducer,
  // todoReducers,
  //form: reduxFormReducer,
});

//const store = createStore(rootReducers);

export const store = createStore(reducers, composeEnhancers(middleware));
