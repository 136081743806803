import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import TravelModuleAdminCalendar from "../../components/TravelModuleAdmin/TravelModuleAdminCalendar";
import TravelModuleAdminArrival from "../../components/TravelModuleAdmin/TravelModuleAdminArrival";

import TravelModuleAdminDeparture from "../../components/TravelModuleAdmin/TravelModuleAdminDeparture";
import TrackerComponent from "../../components/TravelModuleAdmin/TrackerComp";
import ArrivalAndDepartureGroups from "../../components/TravelModuleAdmin/ArrivalAndDepartureGroups";
import Error404 from "../../pages/Error404";
import SinglePlayerTracker from "../../components/TravelModuleAdmin/SinglePlayerTracker";

const routes = [
  {
    path: "/",
    element: <TrackerComponent />,
  },
  { path: "/travel-module-admin", element: <TravelModuleAdminCalendar /> },
  {
    path: "/arrival",
    element: <TravelModuleAdminArrival />,
  },
  {
    path: "/arrival/:id",
    element: <ArrivalAndDepartureGroups />,
  },
  { path: "/departure", element: <TravelModuleAdminDeparture /> },
  {
    path: "/departure/:id",
    element: <ArrivalAndDepartureGroups />,
  },
  {
    path: "/tracker",
    element: <TrackerComponent />,
  },
  {
    path: "/tracker/:id",
    element: <SinglePlayerTracker />,
  },

  // { path: "*", element: <Error404 /> },
];

function TravelModuleAdminRoutes({ defaultRoute }) {
  return (
    <Routes>
      {routes.map(({ path, element }, index) => {
        return <Route key={index} path={path} element={element} />;
      })}
    </Routes>
  );
}

export default TravelModuleAdminRoutes;
