import React, { useEffect, useRef, useState, useMemo } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken = process.env.REACT_APP_MAP_TOKEN;

const MapComponent = ({ playerLatLong, trackedData = [] }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const markersRef = useRef([]);
  const [selectedIndex, setSelectedIndex] = useState(null);

  // Memoize the check if playerLatLong has data
  const hasPlayerLocation = useMemo(
    () => playerLatLong && Object.keys(playerLatLong).length > 0,
    [playerLatLong]
  );

  // Memoize the determination of which data to show
  const latLong = useMemo(() => {
    return hasPlayerLocation
      ? [
          {
            longitude: Number(playerLatLong.longitude),
            latitude: Number(playerLatLong.latitude),
            address: playerLatLong.address,
          },
        ]
      : trackedData?.map(({ latitude, longitude, fullname, address }) => ({
          longitude: Number(longitude),
          latitude: Number(latitude),
          fullname,
          address,
        }));
  }, [hasPlayerLocation, playerLatLong, trackedData]);

  useEffect(() => {
    if (!mapContainerRef.current || !mapboxgl.accessToken) return;

    if (mapRef.current) {
      mapRef.current.remove();
    }

    try {
      const defaultCenter = [77.209, 28.6139];

      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center:
          latLong.length > 0
            ? [latLong[0].longitude, latLong[0].latitude]
            : defaultCenter,
        zoom: hasPlayerLocation ? 15 : 12,
      });

      mapRef.current.on("load", () => {
        mapRef.current.addControl(
          new mapboxgl.NavigationControl(),
          "top-right"
        );

        // Clear existing markers
        markersRef.current.forEach((markerObj) => markerObj.marker.remove());
        markersRef.current = [];

        // Add markers for locations
        markersRef.current = latLong.map(
          ({ longitude, latitude, fullname, address }, index) => {
            const markerElement = document.createElement("div");
            markerElement.className = "custom-marker";
            markerElement.style.width = "30px";
            markerElement.style.height = "30px";
            markerElement.style.cursor = "pointer";
            markerElement.style.transition = "transform 0.3s ease";

            const markerSvg = hasPlayerLocation
              ? `
            <svg viewBox="0 0 24 36" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 0C5.383 0 0 5.383 0 12c0 9 12 24 12 24s12-15 12-24c0-6.617-5.383-12-12-12z" 
                    fill="#FF0000" 
                    stroke="white" 
                    stroke-width="1"/>
              <circle cx="12" cy="12" r="6" fill="white"/>
            </svg>
          `
              : `
            <svg viewBox="0 0 24 36" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 0C5.383 0 0 5.383 0 12c0 9 12 24 12 24s12-15 12-24c0-6.617-5.383-12-12-12z" 
                    fill="#886CC0" 
                    stroke="white" 
                    stroke-width="1"/>
              <circle cx="12" cy="12" r="6" fill="white"/>
              <text x="12" y="15" text-anchor="middle" fill="black" font-size="10">${
                index + 1
              }</text>
            </svg>
          `;

            markerElement.style.backgroundImage =
              "url(data:image/svg+xml;base64," + btoa(markerSvg) + ")";
            markerElement.style.backgroundSize = "contain";
            markerElement.style.backgroundRepeat = "no-repeat";
            markerElement.style.backgroundPosition = "center";

            const popupContent = hasPlayerLocation
              ? `
              <div style="font-family: Arial, sans-serif; padding: 5px;">
                <div>Selected Player Location</div>
                <div>Address: ${address}</div>
              </div>
            `
              : `
              <div style="font-family: Arial, sans-serif; padding: 5px;">
                <div>Name: ${fullname}</div>
                <div>Address: ${address}</div>

              </div>
            `;

            const marker = new mapboxgl.Marker(markerElement)
              .setLngLat([longitude, latitude])
              .setPopup(new mapboxgl.Popup().setHTML(popupContent))
              .addTo(mapRef.current);

            if (!hasPlayerLocation) {
              markerElement.addEventListener("click", () => {
                marker.togglePopup();
              });
            }

            return { marker, element: markerElement };
          }
        );

        if (!hasPlayerLocation && latLong.length > 0) {
          const coordinates = latLong.map(({ longitude, latitude }) => [
            longitude,
            latitude,
          ]);
          const bounds = coordinates.reduce((bounds, coord) => {
            return bounds.extend(coord);
          }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

          mapRef.current.fitBounds(bounds, {
            padding: 50,
            maxZoom: 15,
          });
        } else if (hasPlayerLocation) {
          mapRef.current.flyTo({
            center: [latLong[0].longitude, latLong[0].latitude],
            zoom: 15,
            duration: 1500,
          });
        }
      });
    } catch (error) {
      console.error("Error initializing map:", error);
    }

    return () => {
      try {
        markersRef.current.forEach((markerObj) => markerObj.marker.remove());
        markersRef.current = [];

        if (mapRef.current) {
          mapRef.current.remove();
          mapRef.current = null;
        }
      } catch (error) {
        console.error("Error cleaning up map:", error);
      }
    };
  }, [latLong, hasPlayerLocation]);

  return (
    <div style={{ height: "60vh", width: "100%", position: "relative" }}>
      <div
        ref={mapContainerRef}
        style={{
          height: "100%",
          width: "100%",
          border: "1px solid lightgray",
          borderRadius: "8px",
          overflow: "hidden",
          position: "relative",
        }}
      />

      {!hasPlayerLocation && (
        <div
          style={{
            position: "absolute",
            bottom: "20px",
            left: "20px",
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "4px",
            boxShadow: "0 0 10px rgba(0,0,0,0.1)",
            zIndex: 1000,
            fontSize: "12px",
            fontFamily: "Arial, sans-serif",
            maxHeight: "200px",
            overflowY: "auto",
          }}
        >
          {latLong.map((coord, index) => (
            <div
              key={index}
              style={{
                marginBottom: "5px",
                padding: "5px",
                cursor: "pointer",
                backgroundColor:
                  selectedIndex === index ? "#f0f0f0" : "transparent",
                borderRadius: "4px",
              }}
            >
              <div>Location {index + 1}:</div>
              <div>Name {coord.fullname}:</div>
              <div>
                Lat: {coord.latitude.toFixed(4)}, Lng:{" "}
                {coord.longitude.toFixed(4)}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(MapComponent);
