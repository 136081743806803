import React, { useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import ArrivalModesCard from "./ArrivalModesCard";
import { Button } from "react-bootstrap";
import { MdDelete } from "react-icons/md";
import { MdFlight } from "react-icons/md";
import { FaBus, FaCar, FaTrain, FaUserPlus } from "react-icons/fa";
import { useForm } from "react-hook-form";
import swal from "sweetalert";

import { SiTransportforlondon } from "react-icons/si";
import GenericTable from "./Table";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPlayers,
  getCountryList,
  getDataOfArrivalAndDeparture,
} from "../../../store/actions/TravelAdminActions/TravelAdminActions";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { deleteTravellingPlan } from "../../../store/actions/ContingentTravelActions/ContingentTravelAction";

const transportModes = [
  { id: 1, title: "FLIGHT", icon: <MdFlight size={20} /> },
  { id: 2, title: "TRAIN", icon: <FaTrain size={20} /> },
  { id: 3, title: "BUS", icon: <FaBus size={20} /> },
  { id: 4, title: "CAR", icon: <FaCar size={20} /> },
  { id: 5, title: "OTHERS", icon: <SiTransportforlondon size={20} /> },
  { id: 0 },
];

const columns = [
  { label: "S.No", field: "s_no" },
  { label: "Federation", field: "name" },
  { label: "Department Name", field: "department" },
  { label: "Type", field: "transportation_mode" },
  { label: "Arrival Date", field: "date" },
  { label: "Arrival Time", field: "time" },
  // { label: "Infos", field: "infos" },
  { label: "Size", field: "size_of_team" },
  // { label: "URL", field: "url" },
  { label: "Ticket", field: "doc_path" },
  { label: "Actions", field: "actions" },
];

const actions = [
  {
    label: "Add a Person",
    className: "btn-primary",
    icon: <FaUserPlus />,
    onClick: (e, item) => {
      e.stopPropagation();
      console.log("Add Person clicked", item);
    },
  },
  {
    label: "Delete",
    className: "btn-danger",
    icon: <MdDelete />,
    onClick: handleDelete,
  },
];

//delete plan
export function handleDelete(e, item, dispatch) {
  e.stopPropagation();
  swal({
    title: "Are you sure?",
    text: "",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  }).then(async (willDelete) => {
    let res = willDelete && (await dispatch(deleteTravellingPlan(item.id)));
    if (res) {
      if (res?.success) {
        await swal(res?.message || "Operation was successful", {
          icon: "success",
        });
        await dispatch(dispatch(getDataOfArrivalAndDeparture("arrival", {})));
      } else {
        swal(res?.payload?.message);
      }
    } else {
      swal("Your travelling plan is safe!");
    }
  });
}

function TravelModuleAdminArrival() {
  const [activeLink, setactiveLink] = React.useState(
    transportModes[transportModes.length - 1]?.id
  );
  const navigate = useNavigate();
  const [transportTypeModule, setTransportTypeModule] = React.useState({});

  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();

  const handleActiveBox = (ele) => {
    let { title } = ele;
    dispatch(
      getDataOfArrivalAndDeparture("arrival", {
        transportMode: title,
      })
    );
    setTransportTypeModule({ transportMode: title });
    setactiveLink(ele?.id);
  };
  let { countryList = [], arrivalData = [] } = useSelector(
    (store) => store?.TravelAdminReducer
  );

  useEffect(() => {
    dispatch(getCountryList());
    dispatch(getAllPlayers);
    dispatch(getDataOfArrivalAndDeparture("arrival", {}));
  }, []);

  const handleFilterValue = (data) => {
    let { transportMode } = transportTypeModule;
    data.transportMode = transportMode;
    dispatch(getDataOfArrivalAndDeparture("arrival", data));
  };

  const trOnClickEvent = (element) => {
    if (element && element.id) {
      navigate(`/arrival/${element.id}`);
    } else {
      console.error("Element or ID is undefined");
    }
  };

  return (
    <>
      <PageTitle motherMenu={"Transportation List"} activeMenu={"Arrival"} />
      <ArrivalModesCard
        transportModes={transportModes}
        activeLink={activeLink}
        handleActiveBox={handleActiveBox}
      />

      {/* Filter */}
      <div className="card mt-4">
        <div className="card-body">
          <form onSubmit={handleSubmit(handleFilterValue)}>
            <div className="row">
              {/* Federation Label */}
              <div className="col-lg-2">
                <label>Federation</label>
                <select
                  className="form-control"
                  {...register("federation", {})}
                >
                  <option value="">Select Federation</option>
                  {countryList.map((item, index) => {
                    return (
                      <option key={uuidv4()} value={item?.name}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* Arrival Date Label */}
              <div className="col-lg-2">
                {/* <label>Arrival</label> */}
                <div className="row">
                  <div className="form-group mb-3 col-lg-12">
                    <label>Date of Arrival</label>
                    <input
                      {...register("arrival_date", {})}
                      type="date"
                      className="form-control"
                    />
                    <p className="text-danger"></p>
                  </div>
                </div>
              </div>

              {/* Time Input */}
              <div className="col-lg-2">
                <label> Arrival Time</label>

                <input
                  {...register("arrival_time", {})}
                  type="time"
                  className="form-control"
                />
              </div>

              {/* Reset and Apply Buttons */}
              <div className="col-lg-2">
                <label> Actions</label>
                <div className="row">
                  <div className="col-lg-6">
                    <button
                      onClick={() => {
                        reset();
                        setactiveLink(
                          transportModes[transportModes.length - 1]?.id
                        );
                        dispatch(getDataOfArrivalAndDeparture("arrival", {}));
                      }}
                      type="button"
                      className="btn btn-outline-danger bg-transparent  text-danger border-danger"
                    >
                      Reset
                    </button>
                  </div>
                  <div className="col-lg-6">
                    <Button type="submit">Apply</Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Table */}
      <GenericTable
        columns={columns}
        data={arrivalData}
        dispatch={dispatch}
        actions={actions}
        trOnClickEvent={trOnClickEvent}
      />
    </>
  );
}

export default TravelModuleAdminArrival;
