import {
  addTravelFormFunc,
  deleteTravellingPlanFunc,
  getMember,
  getRoleFunc,
  getTravellingPlansFunc,
  updateTravelFormFunc,
} from "../../../services/api/ContingentServices/contingentServiceFunc";

//Actions
export const ADD_TRAVEL_DATA_REQ = "ADD_TRAVEL_DATA_REQ";
export const ADD_TRAVEL_DATA_REQ_SUCCESS = "ADD_TRAVEL_DATA_REQ_SUCCESS";
export const ADD_TRAVEL_DATA_REQ_FAILED = "ADD_TRAVEL_DATA_REQ_FAILED";

//common cases
export const REQUEST = "REQUEST";
export const FAILURE = "FAILURE";

//role case
export const ROLES_REQ_SUCCESS = "ROLES_REQ_SUCCESS";

// fetch member case
export const GET_MEMBER = "GET_MEMBER";

//Travelling Plans
export const GET_TRAVELLING_PLANS = "GET_TRAVELLING_PLANS";
//update travelling Plan
export const UPDATE_TRAVELLING_PLAN = "UPDATE_TRAVELLING_PLAN";
// ------------------------
//opertion functions

export const addTravelData = (data) => async (dispatch) => {
  try {
    dispatch({ type: ADD_TRAVEL_DATA_REQ });
    let result = await addTravelFormFunc(data);
    return dispatch({
      type: ADD_TRAVEL_DATA_REQ_SUCCESS,
      payload: result?.data,
    });
  } catch (error) {
    dispatch({ type: ADD_TRAVEL_DATA_REQ_FAILED });
    return error.message;
  }
};

//update Travel Data
export const updateTravelData = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: REQUEST });
    let result = await updateTravelFormFunc(data, id);
    return dispatch({
      type: UPDATE_TRAVELLING_PLAN,
      payload: result?.data,
    });
  } catch (error) {
    dispatch({ type: FAILURE });
    return error.message;
  }
};
//fetch roels
export async function getRole(dispatch, getState) {
  try {
    const state = getState();
    if (state.travelreduer.roles?.length > 0) {
      return;
    }
    dispatch({ type: REQUEST });
    let res = await getRoleFunc();
    if (res?.data?.success) {
      return dispatch({
        type: ROLES_REQ_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (error) {
    return dispatch({ type: FAILURE });
  }
}

// fetch members
export function getTeamMember(genderValue, roleValue) {
  //roleValues === sub_category_type ==> true
  return async (dispatch, getState) => {
    // const state = getState();
    // if (state.travelreduer.members?.length > 0) {
    //   return;
    // }
    dispatch({ type: REQUEST });
    try {
      let res = await getMember(genderValue, roleValue);
      return dispatch({ type: GET_MEMBER, payload: res?.data });
    } catch (error) {
      return dispatch({ type: FAILURE });
    }
  };
}

export const getTravellingPlans = (params) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { travellingPlans } = state.travelreduer;
    if (params?.type === "refresh") {
      dispatch({ type: REQUEST });
      const res = await getTravellingPlansFunc();
      return dispatch({ type: GET_TRAVELLING_PLANS, payload: res?.data });
    }
    if (travellingPlans?.length > 0) return;
    dispatch({ type: REQUEST });
    const res = await getTravellingPlansFunc();
    return dispatch({ type: GET_TRAVELLING_PLANS, payload: res?.data });
  } catch (error) {
    dispatch({ type: FAILURE });
  }
};

export const deleteTravellingPlan = (id) => async (dispatch) => {
  try {
    dispatch({ type: REQUEST });
    let res = await deleteTravellingPlanFunc(id);
    return res?.data;
  } catch (error) {
    return dispatch({ type: FAILURE, payload: error });
  }
};
