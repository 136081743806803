import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css"; // Import calendar CSS
import styles from "./styles/CalendarComp.module.css"; // Import module CSS
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";

const localizer = momentLocalizer(moment);

// Event styling function
const eventStyleGetter = (event) => {
  let backgroundColor =
    event.modeOfTransportation === "arrival" ? "#a590cf" : "#64b5f6";
  return {
    style: {
      backgroundColor,
      borderRadius: "5px",
      color: "white",
      border: "none",
      display: "block",
    },
  };
};

// Custom Toolbar
function CustomToolbar({ label, onNavigate, onView }) {
  return (
    <div className={styles.customToolbar}>
      <span>
        <button type="button" onClick={() => onNavigate("PREV")}>
          <GrPrevious />
        </button>
        <button type="button" onClick={() => onNavigate("NEXT")}>
          <GrNext />
        </button>
        <button type="button" onClick={() => onNavigate("TODAY")}>
          today
        </button>
      </span>

      <span className={styles.customToolbarLabel}>{label}</span>

      <span>
        <button type="button" onClick={() => onView("month")}>
          month
        </button>
        <button type="button" onClick={() => onView("week")}>
          week
        </button>
        <button type="button" onClick={() => onView("day")}>
          day
        </button>
      </span>
    </div>
  );
}

function CalendarComp({ events, handleYear: handleNavigate }) {
  // Function to handle navigation
  return (
    <div className={styles.myCustomHeight}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={["month", "week", "day"]}
        components={{
          toolbar: CustomToolbar,
          // event: CustomEvent,
        }}
        eventPropGetter={eventStyleGetter}
        onNavigate={handleNavigate}
      />
    </div>
  );
}

export default CalendarComp;
