import React from "react";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import MutliSelect from "../multiSelect/MutliSelect";
import {
  addTravelData,
  getRole,
  getTeamMember,
  getTravellingPlans,
} from "../../../store/actions/ContingentTravelActions/ContingentTravelAction";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";

const PREVENT_SPECIAL_CHARACTER = /[<>&"\\/:;(){}\[\]|*?#~$!^]/;

function AddTravelPlan({ showAddTravelForm, closeAddTravelForm }) {
  const [selectedMember, setSelectedMember] = React.useState([]);
  const [selectedRole, setSelectedRole] = React.useState([]);
  const [handleError, sethandleError] = React.useState({});
  //redux
  const dispatch = useDispatch();
  const {
    isLoading,
    roles = [],
    members = [],
  } = useSelector((store) => store.travelreduer);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch,
    clearErrors,
    setError,
  } = useForm();

  //watch travel type like example arrival or departure
  const travelType = watch("travel_type");

  React.useEffect(() => {
    setValue("memberType", selectedMember);
    setValue("role", selectedRole);
    setValue("memberSize", selectedMember?.length || 0);
  }, [selectedMember, selectedRole, setValue]);

  // fetch roles
  React.useEffect(() => {
    async function getRoleFunc() {
      try {
        await dispatch(getRole);
      } catch (error) {
        console.log(error);
      }
    }
    getRoleFunc();
  }, []);

  let genderValue = watch("gender");
  //fetch members on the basis of gender
  React.useEffect(() => {
    (async () => {
      try {
        let roleValue = getValues("role"); //sub category types
        roleValue.length > 0 &&
          (await dispatch(getTeamMember(genderValue, roleValue)));
      } catch (error) {
        console.log(error.message);
      }
    })();
  }, [genderValue, getValues, dispatch]);

  //value of travel type example arrival or departure
  let travel_type = watch("travel_type");

  const onSubmit = async (data) => {
    if (isLoading) {
      return;
    }
    if (travel_type === "departure") {
      delete data.arrival_time;
      delete data.arrival_location;
      delete data.date_of_arrival;
    } else if (travel_type === "arrival") {
      delete data.departure_time;
      delete data.departure_location;
      delete data.date_of_departure;
    }

    const formData = new FormData();

    // Add all the fields except members and roles
    Object.keys(data).forEach((key) => {
      if (key === "file") {
        formData.append(key, data[key][0]);
      } else {
        formData.append(key, data[key]);
      }
    });

    // Append selected members as member_ids[0], member_ids[1], etc.
    selectedMember.forEach((member, index) => {
      formData.append(`member_ids[${index}]`, member.value);
    });

    // Append selected roles as sub_category_type_id[0], sub_category_type_id[1], etc.
    selectedRole.forEach((role, index) => {
      formData.append(`sub_category_type_id[${index}]`, role.value);
    });

    //unneccesary keys
    formData.delete("role");
    formData.delete("memberType");
    formData.append("gender_id", formData.get("gender"));
    formData.delete("gender");
    formData.delete("transportNumber");
    formData.delete("memberSize");
    // pass data to the function
    let res = await dispatch(addTravelData(formData));
    if (res?.payload?.success) {
      Swal.fire({
        title: "",
        text: res?.payload?.message,
        icon: "success",
      });
      reset();
      setSelectedRole([]);
      setSelectedMember([]);
      closeAddTravelForm();
      dispatch(getTravellingPlans({ type: "refresh" }));
      sethandleError({});
    }
  };

  const handleInputValidation = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    if (name === "file") {
      const file = e.target.files[0];
      if (file) {
        if (
          file.type === "image/png" ||
          file.type === "application/pdf" ||
          file.type === "image/webp" ||
          file.type === "image/jpeg"
        ) {
          clearErrors("file");
          sethandleError({ ...handleError, [name]: false });
        } else {
          setError("file", {
            type: "manual",
            message: "Allowed formats:JPEG ,PNG, WEBP and PDF",
          });
          sethandleError({ ...handleError, [name]: true });
        }
      }
    } else {
      if (PREVENT_SPECIAL_CHARACTER.test(value)) {
        setError(name, {
          type: "manual",
          message: "Special characters are not allowed.",
        });
        sethandleError({ ...handleError, [name]: true });
      } else {
        clearErrors(name);
        sethandleError({ ...handleError, [name]: false });
      }
    }
  };
  //display
  const handleOpenFile = () => {
    const file = getValues("file");
    if (file && file.length > 0) {
      const fileURL = URL.createObjectURL(file[0]);
      window.open(fileURL, "_blank");
    } else {
      alert("No file uploaded.");
    }
  };

  return (
    <>
      <Modal
        className="modal-lg"
        show={showAddTravelForm}
        onHide={closeAddTravelForm}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-header">
            <h4 className="modal-title fs-20">Travel Plan Form</h4>
            <button
              type="button"
              className="btn-close"
              onClick={closeAddTravelForm}
            >
              <span></span>
            </button>
          </div>

          <div className="modal-body">
            <div className="add-contact-box">
              <div className="add-contact-content">
                {/* Row 1 - Sport/Department and Role */}
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Type <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control"
                      {...register("travel_type", {
                        required: "Type is required",
                      })}
                    >
                      <option value={""}>Type</option>
                      <option value={"arrival"}>Arrival</option>
                      <option value={"departure"}>Departure</option>
                    </select>

                    {errors.travel_type && (
                      <p className="text-danger">
                        {errors.travel_type.message}
                      </p>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Sport/Department <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Sport/Department"
                      {...register("department", {
                        required: "Sport/Department is required",
                        onChange: handleInputValidation,
                        validate: (value) =>
                          value.trim() !== "" || "No spaces allowed",
                      })}
                    />
                    {errors.department && (
                      <p className="text-danger">{errors.department.message}</p>
                    )}
                  </div>
                </div>

                {/* Row 2 - Gender, Type Member Manually, and Member Size */}
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Role <span className="text-danger">*</span>
                    </label>
                    <MutliSelect
                      selected={selectedRole}
                      setSelected={setSelectedRole}
                      options={roles.map((ele) => ({
                        label: ele?.sub_category_name_view,
                        value: ele?.id,
                      }))}
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Gender <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control"
                      {...register("gender", {
                        required: "Gender is required",
                      })}
                    >
                      <option value="">Select Gender</option>
                      <option value="1">Male</option>
                      <option value="2">Female</option>
                      <option value="3">Both</option>
                      {/* <option value="4">Others</option> */}
                    </select>
                    {errors.gender && (
                      <p className="text-danger">{errors.gender.message}</p>
                    )}
                  </div>
                </div>

                {/* Row 2 -Type and Member Size */}

                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Member Manually <span className="text-danger">*</span>
                    </label>
                    <MutliSelect
                      selected={selectedMember}
                      setSelected={setSelectedMember}
                      options={
                        travelType && Array.isArray(members)
                          ? members
                              .filter((mem) =>
                                travelType === "arrival"
                                  ? !mem?.arrival_booked
                                  : !mem?.departure_booked
                              )
                              .map((ele) => ({
                                label: `${ele.first_name} ${ele?.last_name} - ${ele?.kitd_unique_id}`,
                                value: ele?.player_detail_id,
                              }))
                          : []
                      }
                    />
                    {errors.memberType && (
                      <p className="text-danger">{errors.memberType.message}</p>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Member Size <span className="text-danger">*</span>
                    </label>
                    <input
                      disabled
                      value={selectedMember?.length || 0}
                      type="text"
                      className="form-control"
                      placeholder="No. of member size"
                      {...register("memberSize", {
                        required: "Member size is required",
                      })}
                    />
                    {errors.memberSize && (
                      <p className="text-danger">{errors.memberSize.message}</p>
                    )}
                  </div>
                </div>

                {/* Row 3 - Date of Arrival and Arrival Time */}
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      {travel_type === "departure"
                        ? "Date of Departure"
                        : "Date of Arrival"}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      {...register(
                        travel_type === "departure"
                          ? "date_of_departure"
                          : "date_of_arrival",
                        {
                          required:
                            travel_type === "departure"
                              ? "Departure date is required"
                              : "Arrival date is required",
                        }
                      )}
                    />

                    {errors[
                      travel_type === "departure"
                        ? "date_of_departure"
                        : "date_of_arrival"
                    ] && (
                      <p className="text-danger">
                        {
                          errors[
                            travel_type === "departure"
                              ? "date_of_departure"
                              : "date_of_arrival"
                          ].message
                        }
                      </p>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      {travel_type === "departure"
                        ? "Departure Time"
                        : "Arrival Time"}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      {...register(
                        travel_type === "departure"
                          ? "departure_time"
                          : "arrival_time",
                        {
                          required:
                            travel_type === "departure"
                              ? "Departure time is required"
                              : "Arrival time is required",
                          setValueAs: (value) => `${value}:00`,
                        }
                      )}
                    />
                    {errors[
                      travel_type === "departure"
                        ? "departure_time"
                        : "arrival_time"
                    ] && (
                      <p className="text-danger">
                        {
                          errors[
                            travel_type === "departure"
                              ? "departure_time"
                              : "arrival_time"
                          ].message
                        }
                      </p>
                    )}
                  </div>
                </div>

                {/* Row 4 - Mode of Transportation and Transport Number */}
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      Mode of Transportation{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control"
                      {...register("transportation_mode", {
                        required: "Transportation mode is required",
                      })}
                    >
                      <option value="">Select Mode</option>
                      <option value="FLIGHT">FLIGHT</option>
                      <option value="BUS">BUS</option>
                      <option value="TRAIN">TRAIN</option>
                      <option value="CAR">CAR</option>
                      <option value="OTHERS">OTHERS</option>
                    </select>
                    {errors.transportation_mode && (
                      <p className="text-danger">
                        {errors.transportation_mode.message}
                      </p>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Train/Flight No./Bus No./Car No.</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter number"
                      {...register("vehical_no", {
                        required: "Transport Number is required",
                        onChange: handleInputValidation,
                        validate: (value) =>
                          value.trim() !== "" || "No spaces allowed",
                      })}
                    />
                    {errors.vehical_no && (
                      <p className="text-danger">{errors.vehical_no.message}</p>
                    )}
                  </div>
                </div>

                {/* Row 5 - Arrival Location, POC Name, and POC Contact */}
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      {travel_type === "departure"
                        ? "Departure Location"
                        : "Arrival Location"}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={
                        travel_type === "departure"
                          ? "Enter departure location"
                          : "Enter arrival location"
                      }
                      {...register(
                        travel_type === "departure"
                          ? "departure_location"
                          : "arrival_location",
                        {
                          required:
                            travel_type === "departure"
                              ? "Departure location is required"
                              : "Arrival location is required",
                          onChange: handleInputValidation,
                          validate: (value) =>
                            value.trim() !== "" || "No spaces allowed",
                        }
                      )}
                    />
                    {errors[
                      travel_type === "departure"
                        ? "departure_location"
                        : "arrival_location"
                    ] && (
                      <p className="text-danger">
                        {
                          errors[
                            travel_type === "departure"
                              ? "departure_location"
                              : "arrival_location"
                          ].message
                        }
                      </p>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      POC Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter name"
                      {...register("poc_name", {
                        required: "POC name is required",
                        onChange: handleInputValidation,
                        validate: (value) =>
                          value.trim() !== "" || "No spaces allowed",
                      })}
                    />
                    {errors.poc_name && (
                      <p className="text-danger">{errors.poc_name.message}</p>
                    )}
                  </div>
                </div>

                {/* Row 6 - Upload Ticket */}
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>
                      POC Contact <span className="text-danger">*</span>
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Enter contact number"
                      {...register("poc_contact", {
                        required: "POC contact is required",
                        onChange: handleInputValidation,
                        validate: (value) =>
                          value.trim() !== "" || "No spaces allowed",
                      })}
                    />
                    {errors.poc_contact && (
                      <p className="text-danger">
                        {errors.poc_contact.message}
                      </p>
                    )}
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Upload Ticket</label>
                    <div className="input-group">
                      <input
                        type="file"
                        className="form-control"
                        {...register("file", {
                          onChange: handleInputValidation,
                        })}
                      />
                      <span className="input-group-text">
                        <i
                          style={{ cursor: "pointer" }}
                          onClick={handleOpenFile}
                          className="bi bi-eye"
                        ></i>
                      </span>
                    </div>
                    {errors.file && (
                      <p className="text-danger">{errors.file.message}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <Button
              type="submit"
              className="btn btn-primary"
              disabled={
                Object.values(handleError).some((value) => value === true) ||
                selectedMember.length <= 0
              }
            >
              {isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Loading...
                </>
              ) : (
                "Submit"
              )}
            </Button>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                reset();
                setSelectedRole([]);
                setSelectedMember([]);
                sethandleError({});
              }}
            >
              Reset
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}
export default React.memo(AddTravelPlan);
//
// Add Travel Plan Component Done
